// src/data/translations.js
export const translations = {
  en: {
    about: "About",
    skills: "Skills",
    experience: "Experience",
    projects: "Projects",
    education: "Education",
    contact: "Contact",
    hi: "Hi, I am",
    i_am_a: "I am a",
    view_cv: "View CV",
    // Bio translations
    bio_description: "I am a motivated and versatile person, always ready to face new challenges. Passionate about learning, I am dedicated to delivering high-quality results. I possess a positive attitude and a growth mindset, which constantly drives me to improve and develop both personally and professionally.",
    // Skills translations
    technical_skills: "Technical Skills",
    soft_skills: "Soft Skills",
    skill_desc: "These are some of the skills I have been working on and developing over the past 3 years.",
    skill_1: "Ability to quickly and effectively learn new languages.",
    skill_2: "Experience with development tools, frameworks, APIs, and libraries.",
    skill_3: "Understanding software architecture and design effectively.",
    skill_4: "Ability to understand and use third-party APIs.",
    skill_5: "Knowledge of databases and use of different tools.",
    soft_skill_1: "Effective and clear communication skills.",
    soft_skill_2: "Ability to adapt to changes and solve problems.",
    soft_skill_3: "Creativity and ability to think of innovative and effective solutions.",
    soft_skill_4: "Ease of working in teams and leading work teams.",
    soft_skill_5: "Self-management and ability to work independently.",
    // Frameworks translations
    frameworks_title: "Technologies",
    frameworks_desc: "These are some of the frameworks and programming languages I have been working on and developing over the past 3 years.",
    frontend: "Frontend",
    backend: "Backend",
    mobile: "Mobile",
    others: "Others",
    // Roles translations
    full_stack_developer: "Full Stack Developer",
    android_developer: "Android Developer",
    ui_ux_designer: "UI/UX Designer",
    programmer: "Programmer",
    // Experience translations
    experience_title: "Experience",
    experience_desc: "I am a Software Development Technologist with a solid academic foundation and a great interest in applying my knowledge in the development of innovative technological solutions. I am starting my professional career and looking for opportunities to contribute to practical projects, where I can continue learning and developing while adding value with my skills in programming, software design, and problem-solving.",
    experiences: [
      {
        id: 0,
        role: "Fullstack Developer",
        company: "Snake Meta",
        date: "Nov 10, 2023 - Nov 25, 2024",
        desc: "• Enhanced user experiences using Flutter and Hive. Built authentication with JWT and seamless API interactions using FastAPI, optimized with Python and Flask. The code was exported to Android and iOS, updated with new dependencies, and integrated SQLAlchemy with SQLite for the database. Implemented georeferencing using the Google Maps API.",
        skills: [
          "Python",
          "Flask",
          "FastAPI",
          "Flutter",
          "SQLAlchemy",
          "Hive",
          "Firebase",
          "Google Maps API",
        ],
      },
      {
        id: 1,
        role: "Mobile Developer, Debugger, Feature Planner",
        company: "Aktua",
        date: "Feb 13, 2024 - May 18, 2024",
        desc: "• Developed a PWA using Python, implemented Poetry, and integrated Uvicorn with Flask and FastAPI. Managed database queries with PostgreSQL through SQLAlchemy. Designed high-level architecture and interface designs using ProtoPie Cloud. Created efficient and secure endpoints in the backend with Flask and FastAPI, implementing JWT authentication for user security. Optimized API calls by 20% and containerized the application with Docker for streamlined deployment.",
        skills: [
          "Docker",
          "Python",
          "Flask",
          "SQLAlchemy",
          "FastAPI",
          "PostgreSQL",
          "ProtoPie",
          "Flutter",
          "JWT",
        ],
      },
      {
        id: 2,
        role: "Web Developer, Web Designer",
        company: "IT Ingeniera",
        date: "Feb 18, 2024 - Feb 22, 2024",
        desc: "In a Web Programming class, I developed a complete website using only HTML and CSS for selling and managing various services, implementing an interactive design for users. I was recognized for my excellent performance on this project.",
        skills: ["HTML", "CSS"],
      },
      {
        id: 3,
        role: "Web Designer, Frontend Developer",
        company: "InnovaHogarTV",
        date: "Mar 1, 2024 - May 30, 2024",
        desc: "• Developed an e-commerce website using React JS, HTML, CSS, and JavaScript for a home products company. Focused on enhancing user experience with a highly interactive design. Users can easily explore products through an intuitive interface and contact the company directly.",
        skills: ["ReactJs", "NodeJs", "HTML", "CSS", "JavaScript"],
      },
      {
        id: 4,
        role: "Android Developer",
        company: "Google Developers Student Club (GDSC) KIIT",
        date: "Mar 12 - Present",
        desc: "As an Android Developer, I took the initiative to join the Google Developers Student Club (GDSC), where I worked on exciting projects and collaborated with talented developers who share my passion for technology. Through my participation in GDSC, I also joined numerous events, including hackathons, Study Jams, and workshops.",
        skills: [
          "Leadership",
          "Teamwork",
          "Mobile Application Development",
          "Flutter",
          "XML",
          "Figma",
          "Android Studio",
        ],
      },
      {
        id: 5,
        role: "Open Source Contributor",
        company: "GitHub Contributor of Code",
        date: "Mar 12, 2023 - Present",
        desc: "Contributed to various open-source projects and learned from industry experts.",
      },
    ],
    experience_0: {
      role: "Fullstack Developer",
      company: "Snake Meta",
      date: "Nov 10, 2023 - Nov 25, 2024",
      desc: "• Enhanced user experiences using Flutter and Hive. Built authentication with JWT and seamless API interactions using FastAPI, optimized with Python and Flask. The code was exported to Android and iOS, updated with new dependencies, and integrated SQLAlchemy with SQLite for the database. Implemented georeferencing using the Google Maps API.",
      skills: [
        "Python",
        "Flask",
        "FastAPI",
        "Flutter",
        "SQLAlchemy",
        "Hive",
        "Firebase",
        "Google Maps API",
      ],
    },
    experience_1: {
      role: "Mobile Developer, Debugger, Feature Planner",
      company: "Aktua",
      date: "Feb 13, 2024 - May 18, 2024",
      desc: "• Developed a PWA using Python, implemented Poetry, and integrated Uvicorn with Flask and FastAPI. Managed database queries with PostgreSQL through SQLAlchemy. Designed high-level architecture and interface designs using ProtoPie Cloud. Created efficient and secure endpoints in the backend with Flask and FastAPI, implementing JWT authentication for user security. Optimized API calls by 20% and containerized the application with Docker for streamlined deployment.",
      skills: [
        "Docker",
        "Python",
        "Flask",
        "SQLAlchemy",
        "FastAPI",
        "PostgreSQL",
        "ProtoPie",
        "Flutter",
        "JWT",
      ],
    },
    experience_2: {
      role: "Web Developer, Web Designer",
      company: "IT Ingeniera",
      date: "Feb 18, 2024 - Feb 22, 2024",
      desc: "In a Web Programming class, I developed a complete website using only HTML and CSS for selling and managing various services, implementing an interactive design for users. I was recognized for my excellent performance on this project.",
      skills: ["HTML", "CSS"],
    },
    experience_3: {
      role: "Web Designer, Frontend Developer",
      company: "InnovaHogarTV",
      date: "Mar 1, 2024 - May 30, 2024",
      desc: "• Developed an e-commerce website using React JS, HTML, CSS, and JavaScript for a home products company. Focused on enhancing user experience with a highly interactive design. Users can easily explore products through an intuitive interface and contact the company directly.",
      skills: ["ReactJs", "NodeJs", "HTML", "CSS", "JavaScript"],
    },
    experience_4: {
      role: "Android Developer",
      company: "Google Developers Student Club (GDSC) KIIT",
      date: "Mar 12 - Present",
      desc: "As an Android Developer, I took the initiative to join the Google Developers Student Club (GDSC), where I worked on exciting projects and collaborated with talented developers who share my passion for technology. Through my participation in GDSC, I also joined numerous events, including hackathons, Study Jams, and workshops.",
      skills: [
        "Leadership",
        "Teamwork",
        "Mobile Application Development",
        "Flutter",
        "XML",
        "Figma",
        "Android Studio",
      ],
    },
    experience_5: {
      role: "Open Source Contributor",
      company: "GitHub Contributor of Code",
      date: "Mar 12, 2023 - Present",
        desc: "Contributed to various open-source projects and learned from industry experts.",
    },
    experience_0_role: "Fullstack Developer",
    experience_0_company: "Snake Meta",
    experience_0_date: "Nov 10, 2023 - Nov 25, 2024",
    experience_0_desc: "• Enhanced user experiences using Flutter and Hive. Built authentication with JWT and seamless API interactions using FastAPI, optimized with Python and Flask. The code was exported to Android and iOS, updated with new dependencies, and integrated SQLAlchemy with SQLite for the database. Implemented georeferencing using the Google Maps API.",
    experience_1_role: "Mobile Developer, Debugger, Feature Planner",
    experience_1_company: "Aktua",
    experience_1_date: "Feb 13, 2024 - May 18, 2024",
    experience_1_desc: "• Developed a PWA using Python, implemented Poetry, and integrated Uvicorn with Flask and FastAPI. Managed database queries with PostgreSQL through SQLAlchemy. Designed high-level architecture and interface designs using ProtoPie Cloud. Created efficient and secure endpoints in the backend with Flask and FastAPI, implementing JWT authentication for user security. Optimized API calls by 20% and containerized the application with Docker for streamlined deployment.",
    experience_2_role: "Web Developer, Web Designer",
    experience_2_company: "IT Ingeniera",
    experience_2_date: "Feb 18, 2024 - Feb 22, 2024",
    experience_2_desc: "In a Web Programming class, I developed a complete website using only HTML and CSS for selling and managing various services, implementing an interactive design for users. I was recognized for my excellent performance on this project.",
    experience_3_role: "Web Designer, Frontend Developer",
    experience_3_company: "InnovaHogarTV",
    experience_3_date: "Mar 1, 2024 - May 30, 2024",
    experience_3_desc: "• Developed an e-commerce website using React JS, HTML, CSS, and JavaScript for a home products company. Focused on enhancing user experience with a highly interactive design. Users can easily explore products through an intuitive interface and contact the company directly.",
    experience_4_role: "Android Developer",
    experience_4_company: "Google Developers Student Club (GDSC) KIIT",
    experience_4_date: "Mar 12, 2024",
    experience_4_desc: "As an Android Developer, I took the initiative to join the Google Developers Student Club (GDSC), where I worked on exciting projects and collaborated with talented developers who share my passion for technology. Through my participation in GDSC, I also joined numerous events, including hackathons, Study Jams, and workshops.",
    experience_5_role: "Open Source Contributor",
    experience_5_company: "GitHub Contributor of Code",
    experience_5_date: "Mar 12, 2023 - Present",
    experience_5_desc: "Contributed to various open-source projects and learned from industry experts.",
    // Education translations
    education_school_0: "Rembrandt School",
    education_degree_0: "High School with Business Emphasis",
    education_desc_0: "I graduated from high school in 2021 from Rembrandt School in Bogotá, Colombia. During my time at this institution, I not only acquired academic knowledge but also cultivated fundamental values that have shaped my character and guided my actions. I learned the value of responsibility by fulfilling my school duties and actively participating in extracurricular activities. Honesty became a central pillar in my life by being transparent in my actions and relationships. Empathy flourished in me by understanding my peers' perspectives and providing support when needed.",
    education_school_1: "Minuto de Dios University Corporation San Juan Eudes Campus",
    education_degree_1: "Software Development Technologist",
    education_desc_1: "Technologist in training in Software Development at the Minuto de Dios University Corporation. During my time at the university, I have acquired a solid understanding of the fundamental principles of software development and advanced techniques for creating innovative applications. My focus on software development allowed me to reach the fifth semester of my academic program, providing me with a solid foundation in areas such as programming, database design, and web and mobile application development.",
    education_school_2: "Language Center - Unillanos",
    education_degree_2: "English Advanced C1",
    education_desc_2: "During my time at the Language Center - Unillanos, I am completing the English Advanced C1 program, reaching the C1 level in English. This experience has provided me with advanced skills in listening, speaking, reading, and writing in English. I am capable of effectively communicating in various academic, professional, and social settings in English.",
    education_title: "Education",
    education_desc: "My educational journey has been a path of self-exploration and personal development, where I have had the opportunity to discover and grow in various aspects. Below is a detailed account of my educational background:",
    grade_label: "Status",
    skills_label: "Skills",
    skill_0_0: "Effective Communication",
    skill_0_1: "Empathy",
    skill_0_2: "Conflict Resolution",
    skill_0_3: "Negotiation Skills",
    skill_0_4: "Emotion Management",
    skill_1_0: "Project Leadership",
    skill_1_1: "Database Design",
    skill_1_2: "Web Application Development",
    skill_1_3: "Problem Solving",
    skill_1_4: "Teamwork",
    skill_2_0: "Speaking",
    skill_2_1: "Writing",
    skill_2_2: "Reading",
    skill_2_3: "Listening",
    skill_2_4: "Grammar",
    // Add more translations as needed
    projects_title: "Projects",
    projects_desc: "I have been involved in a wide range of projects, from creating web applications to developing Android apps. Here are some examples of what I have done.",
    all: "All",
    web_apps: "Web Apps",
    android_apps: "Mobile Apps",
    machine_learning: "Machine Learning",
    go_to_code: "Go to Code",
    go_to_live_app: "Go to Live App",
    members: "Members",
    language: "Language",
    spanish: "Spanish",
    english: "English",
    github_profile: "Github Profile",
    project_details: {
      0: {
        title: "Podstream",
        date: "Apr 2023 - May 2023",
        description: "Supported a complete web application that allows users to search, play, and pause their favorite podcasts on demand and create podcasts. User authentication was implemented using Google Auth and Jwt Auth, a responsive user interface was created with React JS that provides users with a seamless experience across all devices. Practiced agile methodologies to optimize team efficiency and communication.",
        tags: ["React Js", "MongoDb", "Node Js", "Express Js", "Redux"],
      },
      1: {
        title: "Tableros",
        date: "March 2024 - March 2024",
        description: "It is an application that uses Vue.js to create and manage visual boards, probably with the aim of displaying data, metrics, or information organized in cards or panels. This project could include functionalities such as: Creation, editing, and deletion of custom boards or cards. Interaction with data dynamically and in real-time. Use of Vue.js components to structure the interface modularly, allowing a smooth and responsive user experience. Possible integration with charts or visualizations to represent information visually. The project can be ideal for practicing the creation of interactive and reactive interfaces using the Vue.js framework.",
        tags: ["VueJs", "HTML", "CSS", "JavaScript", "GoogleAPI", "Material UI"],
        category: "web app",
      },
      2: {
        title: "Brain Tumor Detection",
        date: "Jan 2023 - Mar 2023",
        description: "Supported a Web Application which preprocessed and augmented the dataset to improve model accuracy, trained the model, improved the API using the model and Python, and used the React web application for the project interface. Achievements: Achieved 99.2% accuracy in accurately detecting brain tumors from medical images.",
        tags: ["Python", "Keras", "TensorFlow", "VGG16", "Pickle", "React"],
      },
      3: {
        title: "Snake - Meta",
        date: "Nov 2023 - Nov 2024",
        description: "The Mobile Application is planned to be launched on Playstore in November 2024. This project proposes the 'Development of a Mobile Application to prevent, inform, and preserve the different species of snakes in Meta'. It keeps a record of all the snakes in the Department through an API with their Taxonomy, Data, Information, and Georeferencing. It has the Google Maps API to georeference the snake in the user room database. Made with Flutter in MVVM Architecture and Live Data.",
        tags: ["Python", "Flask", "FastAPI", "Flutter", "SQLAlchemy", "Hive", "Firebase", "Google Maps API"],
      },
      5: {
        title: "IT Ingenieria - Landing Page",
        date: "March 2024 - March 2024",
        description: "Developed a Landing Page for an Engineering company for my Web Programming class. The Landing Page was created with HTML, CSS, and JavaScript. It has a home page with an image slider, a services section, a projects section, a contact section, and a footer.",
        tags: ["HTML", "CSS", "JavaScript", "Material UI"],
      },
      9: {
        title: "Houseplant Store",
        date: "September 2024 - September 2024",
        description: "This project is about a plant store developed in React that uses Redux for state management. The main purpose of the project is to offer a platform for selling plants, allowing users to explore different product options, add items to the shopping cart, and manage their transactions efficiently. The focus on Redux ensures that the application handles state efficiently, facilitating inventory updates, tracking customer selections, and managing orders. Additionally, the interface design aims to be attractive and functional, offering a smooth experience on both mobile and desktop devices.",
        tags: ["React Js", "HTML", "CSS", "Redux", "JavaScript", "Material UI"],
      },
      10: {
        title: "Aktua",
        date: "Feb 2024 - May 2024",
        description: "Developed a PWA (Progressive Web Application) in a Scrum framework with the aim of creating and fostering united, safe, and collaborative neighborhood communities. Used Python to connect with FastAPI, Uvicorn, Poetry services, and SqlAlchemy for database connection with PostgreSQL. Created services for Posts, Comments, and Ratings for users and Dockerized the project to make it cross-platform. With Flutter, we created the application for mobile devices, making it compatible with iOS and Android.",
        tags: ["Docker", "Python", "Flask", "SQLAlchemy", "FastAPI", "PostgreSQL", "ProtoPie", "Flutter", "JWT"],
      },
      11: {
        title: "2 Type",
        date: "March 2024 - March 2023",
        description: "A web page connected through 2 APIs, one from TheMovieDB and GoogleTranslate created with VueJS and Material UI. It has a home page where users can view a list of movies. It has a search bar where users can search for movies by their title, it also has a translator, search filter by genre, mode change, and pagination. At the bottom of the page, I created a footer with my data and the page's data.",
        tags: ["Vue Js", "HTML", "CSS", "Tailwind CSS", "Material UI"],
      },
      12: {
        title: "Landing Page - Unillanos",
        date: "Oct 2022 - Jan 2023",
        description: "Diseñé y desarrollé una página de aterrizaje con Astro, Tailwind CSS y Material UI, es una página web dedicada a los agradecimientos por nuestra graduación de compañeros de estudio. Implementé un diseño creativo donde se puede ver el discurso de agradecimiento en varios idiomas, inglés y español, y también implementé un carrusel de fotos de los estudiantes que se graduarán. Hice un seguimiento de la página de aterrizaje para mejorar la efectividad y calidez del proyecto.",
        tags: ["Astro", "HTML", "CSS", "JavaScript", "Tailwind CSS", "Material UI"],
      },
    },
  },
  es: {
    about: "Acerca",
    skills: "Habilidades",
    experience: "Experiencia",
    projects: "Proyectos",
    education: "Educación",
    contact: "Contacto",
    hi: "Hola, soy",
    i_am_a: "Soy un",
    view_cv: "Ver Hoja de Vida",
    // Bio translations
    bio_description: "Soy una persona motivada y versátil, siempre dispuesta a enfrentar nuevos desafíos. Apasionado por el aprendizaje, me dedico a ofrecer resultados de alta calidad. Poseo una actitud positiva y una mentalidad de crecimiento, lo que me impulsa constantemente a mejorar y desarrollarme tanto personal como profesionalmente.",
    // Skills translations
    technical_skills: "Habilidades Técnicas",
    soft_skills: "Habilidades Blandas",
    skill_desc: "Estas son algunas de las habilidades en las que he estado trabajando y desarrollándome durante los últimos 3 años.",
    skill_1: "Capacidad de aprender nuevos lenguajes de forma rápida y eficaz.",
    skill_2: "Experiencia con herramientas de desarrollo frameworks, APIs y librerías.",
    skill_3: "Comprender la arquitectura de software y su diseño de manera efectiva.",
    skill_4: "Capacidad de entender y utilizar APIs de terceros.",
    skill_5: "Conocimientos de base de datos y uso de diferentes herramientas.",
    soft_skill_1: "Capacidad de comunicación efectiva y clara.",
    soft_skill_2: "Capacidad de adaptación a cambios y solución de problemas.",
    soft_skill_3: "Creatividad y habilidad de pensar en soluciones innovadoras y efectivas.",
    soft_skill_4: "Facilidad para trabajar en equipo y liderar equipos de trabajo.",
    soft_skill_5: "Autogestión y capacidad de trabajar de forma autónoma.",
    // Frameworks translations
    frameworks_title: "Tecnologías",
    frameworks_desc: "Estas son algunas de las tecnologías, frameworks y lenguajes de programación en los que he estado trabajando y desarrollándome durante los últimos 3 años.",
    frontend: "Frontend",
    backend: "Backend",
    mobile: "Móvil",
    others: "Otros",
    // Roles translations
    full_stack_developer: "Desarrollador Full Stack",
    android_developer: "Desarrollador Android",
    ui_ux_designer: "Diseñador UI/UX",
    programmer: "Programador",
    // Experience translations
    experience_title: "Experiencia",
    experience_desc: "Soy Tecnólogo en Desarrollo de Software, con una sólida base académica y un gran interés en aplicar mis conocimientos en el desarrollo de soluciones tecnológicas innovadoras. Estoy iniciando mi trayectoria profesional y busco oportunidades para contribuir a proyectos prácticos, donde pueda continuar aprendiendo y desarrollándome mientras aporto valor con mis habilidades en programación, diseño de software y resolución de problemas.",
    experience_0: {
      role: "Desarrollador Fullstack",
      company: "Snake Meta",
      date: "10 Nov, 2023 - 25 Nov, 2024",
      desc: "• Experiencias de usuario mejoradas utilizando Flutter y Hive. Construí la autenticacion utilizando JWT con una interacción perfecta de las API REST utilizando FastAPI optimizado con Python y Flask. Con el Código se exporto a Android e IOS, actualizado con nuevas dependencias e integrando SQLAlchemy con SQLite para la base de datos. Se implemento Georeferenciacion con la API de Google Maps",
      skills: [
        "Python",
        "Flask",
        "FastAPI",
        "Flutter",
        "SQLAlchemy",
        "Hive",
        "Firebase",
        "Google Maps API",
      ],
    },
    experience_1: {
      role: "Desarrollador Móvil, Depuración, Redacción de nuevas ideas, Redacción de nuevas funciones.",
      company: "Aktua",
      date: "13 Feb, 2024 - 18 May, 2024",
      desc: "• Desarrollé una PWA utilizando Python, donde implementé Poetry, Uvicorn integrado con Flask y FastAPI, y manejé las consultas a la base de datos PostgreSQL mediante SQLAlchemy. Además, diseñé la arquitectura de alto nivel y los diseños de interfaz utilizando ProtoPie Cloud. En el backend, utilicé Flask y FastAPI para crear endpoints eficientes y seguros, implementando autenticación JWT para la seguridad del usuario. Logré reducir las llamadas a la API en un 20% mediante optimizaciones en el código y la base de datos. Dockericé la aplicación para facilitar su despliegue y la automatización de procesos.",
      skills: [
        "Docker",
        "Python",
        "Flask",
        "SQLAlchemy",
        "FastAPI",
        "PostgreSQL",
        "ProtoPie",
        "Flutter",
        "JWT",
      ],
    },
    experience_2: {
      role: "Desarrollador Web, Diseñador Web",
      company: "IT Ingeniera",
      date: "18 Feb, 2024 - 22 Feb, 2024",
      desc: "En clase de Programación Web, Desarrolle una página web completa utilizando solo HTML y CSS para la venta y gestión de diferentes servicios implementando un diseño interactivo para el usuario. Fui el de mejor desempeño en el trabajo",
      skills: [
        "HTML",
        "CSS",
      ],
    },
    experience_3: {
      role: "Diseñador Web, Desarollador Frontend",
      company: "InnovaHogarTV",
      date: "1 Mar, 2024 - 30 May, 2024",
      desc: "• Desarrollé un sitio web de comercio electrónico utilizando tecnologías como React JS, HTML, CSS y JavaScript para una empresa dedicada a la venta de productos para el hogar. En este proyecto, me enfoqué en mejorar la experiencia del usuario mediante la creación de una página altamente interactiva. Los usuarios tienen la capacidad de explorar fácilmente los productos ofrecidos, gracias a una interfaz intuitiva y receptiva. Además, implementé funcionalidades que permiten a los usuarios contactar directamente con la empresa, facilitando así la comunicación y la satisfacción del cliente.",
      skills: [
        "ReactJs",
        "NodeJs",
        "HTML",
        "CSS",
        "JavaScript",
      ],
    },
    experience_4: {
      role: "Desarrollador Android",
      company: "Google Developers Student Club (GDSC) KIIT",
      date: "12 Mar, 2024",
      desc: "Como desarrollador de Android tome la iniciativa de particiar en Google Developers Student Club (GDCS), teniendo la oportunidad de trabajar en proyectos interesantes y colaborar con desarrolladores talentosos que comparten mi pasión por la tecnología. A través de mi participación en GDCS, también tuve la oportunidad de participar en numerosos eventos, incluidos hackathons, Study Jams y talleres.",
      skills: [
        "Leadership",
        "Teamwork",
        "Mobile Application Development",
        "Flutter",
        "XML",
        "Figma",
        "Android Studio",
      ],
    },
    experience_5: {
      role: "Contribuidor de Código Abierto",
      company: "Github Contributor of Code",
      date: "12 Mar 2023 - Present",
      desc: "Contribuí a diferentes proyectos de código abierto y aprendí de expertos de la industria.",
    },
    experience_0_role: "Desarrollador Fullstack",
    experience_0_company: "Snake Meta",
    experience_0_date: "10 Nov, 2023 - 25 Nov, 2024",
    experience_0_desc: "• Experiencias de usuario mejoradas utilizando Flutter y Hive. Construí la autenticacion utilizando JWT con una interacción perfecta de las API REST utilizando FastAPI optimizado con Python y Flask. Con el Código se exporto a Android e IOS, actualizado con nuevas dependencias e integrando SQLAlchemy con SQLite para la base de datos. Se implemento Georeferenciacion con la API de Google Maps",
    experience_1_role: "Desarrollador Móvil, Depuración, Redacción de nuevas ideas, Redacción de nuevas funciones.",
    experience_1_company: "Aktua",
    experience_1_date: "13 Feb, 2024 - 18 May, 2024",
    experience_1_desc: "• Desarrollé una PWA utilizando Python, donde implementé Poetry, Uvicorn integrado con Flask y FastAPI, y manejé las consultas a la base de datos PostgreSQL mediante SQLAlchemy. Además, diseñé la arquitectura de alto nivel y los diseños de interfaz utilizando ProtoPie Cloud. En el backend, utilicé Flask y FastAPI para crear endpoints eficientes y seguros, implementando autenticación JWT para la seguridad del usuario. Logré reducir las llamadas a la API en un 20% mediante optimizaciones en el código y la base de datos. Dockericé la aplicación para facilitar su despliegue y la automatización de procesos.",
    experience_2_role: "Desarrollador Web, Diseñador Web",
    experience_2_company: "IT Ingeniera",
    experience_2_date: "18 Feb, 2024 - 22 Feb, 2024",
    experience_2_desc: "En clase de Programación Web, Desarrolle una página web completa utilizando solo HTML y CSS para la venta y gestión de diferentes servicios implementando un diseño interactivo para el usuario. Fui el de mejor desempeño en el trabajo",
    experience_3_role: "Diseñador Web, Desarollador Frontend",
    experience_3_company: "InnovaHogarTV",
    experience_3_date: "1 Mar, 2024 - 30 May, 2024",
    experience_3_desc: "• Desarrollé un sitio web de comercio electrónico utilizando tecnologías como React JS, HTML, CSS y JavaScript para una empresa dedicada a la venta de productos para el hogar. En este proyecto, me enfoqué en mejorar la experiencia del usuario mediante la creación de una página altamente interactiva. Los usuarios tienen la capacidad de explorar fácilmente los productos ofrecidos, gracias a una interfaz intuitiva y receptiva. Además, implementé funcionalidades que permiten a los usuarios contactar directamente con la empresa, facilitando así la comunicación y la satisfacción del cliente.",
    experience_4_role: "Desarrollador Android",
    experience_4_company: "Google Developers Student Club (GDSC) KIIT",
    experience_4_date: "12 Mar, 2024",
    experience_4_desc: "Como desarrollador de Android tome la iniciativa de particiar en Google Developers Student Club (GDCS), teniendo la oportunidad de trabajar en proyectos interesantes y colaborar con desarrolladores talentosos que comparten mi pasión por la tecnología. A través de mi participación en GDCS, también tuve la oportunidad de participar en numerosos eventos, incluidos hackathons, Study Jams y talleres.",
    experience_5_role: "Contribuidor de Código Abierto",
    experience_5_company: "Github Contributor of Code",
    experience_5_date: "12 Mar, 2023 - Present",
    experience_5_desc: "Contribuí a diferentes proyectos de código abierto y aprendí de expertos de la industria.",
    // Education translations
    education_school_0: "Colegio Rembrandt",
    education_degree_0: "Bachillerato con Enfasís Empresarial",
    education_desc_0: "Me gradué de bachillerato en 2021 del colegio Rembrandt en Bogotá, Colombia. Durante mi tiempo en esta institución, no solo adquirí conocimientos académicos, sino que también cultivé valores fundamentales que han moldeado mi carácter y guiado mis acciones. Aprendí el valor de la responsabilidad al cumplir con mis deberes escolares y participar activamente en actividades extracurriculares. La honestidad se convirtió en un pilar central en mi vida al ser transparente en mis acciones y relaciones. La empatía floreció en mí al entender las perspectivas de mis compañeros y brindar apoyo cuando era necesario.",
    education_school_1: "Corporación Universitaria Minuto de Dios sede San Juan Eudes",
    education_degree_1: "Tecnólogo En Desarollo de Software",
    education_desc_1: "Tecnólogo en formación en Desarrollo de Software en la Corporación Universitaria Minuto de Dios. Durante mi tiempo en la universidad, he adquirido una sólida comprensión de los principios fundamentales del desarrollo de software y técnicas avanzadas para la creación de aplicaciones innovadoras. Mi enfoque en el desarrollo de software me permitió alcanzar el quinto semestre de mi programa académico, lo que me brindó una base sólida en áreas como programación, diseño de bases de datos y desarrollo de aplicaciones web y móviles.",
    education_school_2: "Centro de Idiomas - Unillanos",
    education_degree_2: "English Advanced C1",
    education_desc_2: "Durante mi tiempo en el Centro de Idiomas - Unillanos, estoy completando el programa de English Advanced C1, alcanzando el nivel C1 en inglés. Esta experiencia me ha proporcionado habilidades avanzadas en comprensión auditiva, expresión oral, lectura y escritura en inglés. Estoy capacitado para comunicarme efectivamente en diversos entornos académicos, profesionales y sociales en inglés.",
    education_title: "Educación",
    education_desc: "Mi trayectoria educativa ha sido un viaje de autoexploración y desarrollo personal, donde he tenido la oportunidad de descubrir y crecer en diversos aspectos. A continuación, se detalla mi formación educativa:",
    grade_label: "Estado",
    skills_label: "Habilidades",
    skill_0_0: "Comunicación Efectiva",
    skill_0_1: "Empatía",
    skill_0_2: "Resolución de Conflictos",
    skill_0_3: "Habilidades de Negociación",
    skill_0_4: "Gestión de Emociones",
    skill_1_0: "Liderazgo en Proyectos",
    skill_1_1: "Diseño de Bases de Datos",
    skill_1_2: "Desarollo de Aplicaciones Web",
    skill_1_3: "Resolución de Problemas",
    skill_1_4: "Trabajo en Equipo",
    skill_2_0: "Speaking",
    skill_2_1: "Writing",
    skill_2_2: "Reading",
    skill_2_3: "Listening",
    skill_2_4: "Grammar",
    // Add more translations as needed
    projects_title: "Proyectos",
    projects_desc: "He estado involucrado en una gran cantidad de proyectos, desde la creación de aplicaciones web hasta el desarrollo de apps para Android. Aquí tienes algunos ejemplos de lo que he hecho.",
    all: "Todos",
    web_apps: "Aplicaciones Web",
    android_apps: "Aplicaciones Móviles",
    machine_learning: "Aprendizaje Automático",
    go_to_code: "Ir al Código",
    go_to_live_app: "Ir a la App en Vivo",
    members: "Miembros",
    language: "Idioma",
    spanish: "Español",
    english: "Inglés",
    github_profile: "Perfil Github",
    project_details: {
      0: {
        title: "Podstream",
        date: "Abr 2023 - May 2023",
        description: "Apoyé una aplicación web completa que permite a los usuarios buscar, reproducir y pausar sus podcasts favoritos a demanda y crear podcasts. La autenticación de usuarios se implementó utilizando Google Auth y Jwt Auth, se creó una interfaz de usuario receptiva con React JS que proporciona a los usuarios una experiencia perfecta en todos los dispositivos. Practiqué metodologías ágiles para optimizar la eficiencia y la comunicación del equipo.",
        tags: ["React Js", "MongoDb", "Node Js", "Express Js", "Redux"],
      },
      1: {
        title: "Tableros",
        date: "Marzo 2024 - Marzo 2024",
        description: "Es una aplicación que utiliza Vue.js para crear y gestionar tableros visuales, probablemente con el objetivo de mostrar datos, métricas o información organizada en tarjetas o paneles. Este proyecto podría incluir funcionalidades como: creación, edición y eliminación de tableros o tarjetas personalizadas. Interacción con datos de manera dinámica y en tiempo real. Uso de componentes de Vue.js para estructurar la interfaz de manera modular, permitiendo una experiencia de usuario fluida y receptiva. Posible integración con gráficos o visualizaciones para representar información visualmente. El proyecto puede ser ideal para practicar la creación de interfaces interactivas y reactivas utilizando el marco Vue.js.",
        tags: ["VueJs", "HTML", "CSS", "JavaScript", "GoogleAPI", "Material UI"],
        category: "web app",
      },
      2: {
        title: "Detección de Tumores Cerebrales",
        date: "Ene 2023 - Mar 2023",
        description: "Apoyé una aplicación web que preprocesó y aumentó el conjunto de datos para mejorar la precisión del modelo, entrené el modelo, mejoré la API utilizando el modelo y Python, y utilicé la aplicación web React para la interfaz del proyecto. Logros: Logré una precisión del 99.2% en la detección precisa de tumores cerebrales a partir de imágenes médicas.",
        tags: ["Python", "Keras", "TensorFlow", "VGG16", "Pickle", "React"],
      },
      3: {
        title: "Snake - Meta",
        date: "Nov 2023 - Nov 2024",
        description: "La aplicación móvil está planeada para ser lanzada en Playstore en noviembre de 2024. Este proyecto propone el 'Desarrollo de una aplicación móvil para prevenir, informar y preservar las diferentes especies de serpientes en Meta'. Mantiene un registro de todas las serpientes en el Departamento a través de una API con su taxonomía, datos, información y georreferenciación. Tiene la API de Google Maps para georreferenciar la serpiente en la base de datos de la sala del usuario. Hecho con Flutter en arquitectura MVVM y Live Data.",
        tags: ["Python", "Flask", "FastAPI", "Flutter", "SQLAlchemy", "Hive", "Firebase", "Google Maps API"],
      },
      5: {
        title: "IT Ingenieria - Página de Aterrizaje",
        date: "Marzo 2024 - Marzo 2024",
        description: "Desarrollé una página de aterrizaje para una empresa de ingeniería para mi clase de Programación Web. La página de aterrizaje se creó con HTML, CSS y JavaScript. Tiene una página de inicio con un deslizador de imágenes, una sección de servicios, una sección de proyectos, una sección de contacto y un pie de página.",
        tags: ["HTML", "CSS", "JavaScript", "Material UI"],
      },
      9: {
        title: "Tienda de Plantas",
        date: "Septiembre 2024 - Septiembre 2024",
        description: "Este proyecto trata sobre una tienda de plantas desarrollada en React que utiliza Redux para la gestión del estado. El objetivo principal del proyecto es ofrecer una plataforma para la venta de plantas, permitiendo a los usuarios explorar diferentes opciones de productos, agregar artículos al carrito de compras y gestionar sus transacciones de manera eficiente. El enfoque en Redux asegura que la aplicación maneje el estado de manera eficiente, facilitando las actualizaciones de inventario, el seguimiento de las selecciones de los clientes y la gestión de pedidos. Además, el diseño de la interfaz tiene como objetivo ser atractivo y funcional, ofreciendo una experiencia fluida tanto en dispositivos m��viles como de escritorio.",
        tags: ["React Js", "HTML", "CSS", "Redux", "JavaScript", "Material UI"],
      },
      10: {
        title: "Aktua",
        date: "Feb 2024 - May 2024",
        description: "Desarrollé una PWA (Aplicación Web Progresiva) en un marco Scrum con el objetivo de crear y fomentar comunidades de vecindarios unidas, seguras y colaborativas. Utilicé Python para conectarme con los servicios de FastAPI, Uvicorn, Poetry y SqlAlchemy para la conexión de la base de datos con PostgreSQL. Creé servicios para publicaciones, comentarios y calificaciones de usuarios y Dockericé el proyecto para hacerlo multiplataforma. Con Flutter, creamos la aplicación para dispositivos móviles, haciéndola compatible con iOS y Android.",
        tags: ["Docker", "Python", "Flask", "SQLAlchemy", "FastAPI", "PostgreSQL", "ProtoPie", "Flutter", "JWT"],
      },
      11: {
        title: "2 Type",
        date: "Marzo 2024 - Marzo 2023",
        description: "Una página web conectada a través de 2 APIs, una de TheMovieDB y GoogleTranslate creada con VueJS y Material UI. Tiene una página de inicio donde los usuarios pueden ver una lista de películas. Tiene una barra de búsqueda donde los usuarios pueden buscar películas por su título, también tiene un traductor, filtro de búsqueda por género, cambio de modo y paginación. En la parte inferior de la página, creé un pie de página con mis datos y los datos de la página.",
        tags: ["Vue Js", "HTML", "CSS", "Tailwind CSS", "Material UI"],
      },
      12: {
        title: "Landing Page - Unillanos",
        date: "Oct 2022 - Jan 2023",
        description: "Diseñé y desarrollé una página de aterrizaje con Astro, Tailwind CSS y Material UI, es una página web dedicada a los agradecimientos por nuestra graduación de compañeros de estudio. Implementé un diseño creativo donde se puede ver el discurso de agradecimiento en varios idiomas, inglés y español, y también implementé un carrusel de fotos de los estudiantes que se graduarán. Hice un seguimiento de la página de aterrizaje para mejorar la efectividad y calidez del proyecto.",
        tags: ["Astro", "HTML", "CSS", "JavaScript", "Tailwind CSS", "Material UI"],
      },
    },
  },
};